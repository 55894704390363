import { Action } from '@ngrx/store';
import { Report } from '@touch-apps/reports-model';
import { QuestionsActionTypes } from '../../questions/state/questions.actions';
import { QuestionType } from '@touch-apps/questions-model';

export enum ReportActionTypes {
  LOAD_REPORTS = '[Report] Load Reports',
  LOAD_REPORTS_SUCCESS = '[Report] Load Reports success',
  LOAD_REPORTS_FAILURE = '[Report] Load Reports failure',
  SET_REPORT_ID_BEING_EDITED = '[Report] Set report id being edited',
  SAVE_REPORT = '[Report] Save report',
  SAVE_REPORT_SUCCESS = '[Report] Save report success',
  SAVE_REPORT_FAILURE = '[Report] Save report failure',
  DELETE_REPORT = '[Report] Delete report',
  DELETE_REPORT_SUCCESS = '[Report] Delete report success',
  DELETE_REPORT_FAILURE = '[Report] Delete report failure',
  CREATE_REPORT = '[Report] Create report',
  CREATE_REPORT_SUCCESS = '[Report] Create report success',
  // todo - add create report failure
  COMPLETE_REPORT = '[Report] Complete report',
  COMPLETE_REPORT_SUCCESS = '[Report] Complete report success',
  COMPLETE_REPORT_FAILURE = '[Report] Complete report failure',
  SET_REPORT_QUESTIONS = '[Report] Set Report Questions',
  GET_REPORT_QUESTIONS = '[Report] Get Report Questions',
}

export class LoadReports implements Action {
  readonly type = ReportActionTypes.LOAD_REPORTS;

  constructor() {}
}

export class LoadReportsSuccess implements Action {
  readonly type = ReportActionTypes.LOAD_REPORTS_SUCCESS;

  constructor(public reports: any) {}
}

export class LoadReportsFailure implements Action {
  readonly type = ReportActionTypes.LOAD_REPORTS_FAILURE;

  constructor(public error: any) {}
}

export class SetReportIdBeingEdited implements Action {
  readonly type = ReportActionTypes.SET_REPORT_ID_BEING_EDITED;

  constructor(public reportId: string) {}
}

export class SaveReport implements Action {
  readonly type = ReportActionTypes.SAVE_REPORT;

  constructor(public report: Report) {}
}

export class SaveReportSuccess implements Action {
  readonly type = ReportActionTypes.SAVE_REPORT_SUCCESS;

  constructor(public report: any) {}
}

export class SaveReportFailure implements Action {
  readonly type = ReportActionTypes.SAVE_REPORT_FAILURE;

  constructor(public error: any) {}
}

export class DeleteReport implements Action {
  readonly type = ReportActionTypes.DELETE_REPORT;

  constructor(public reportId: string) {}
}

export class DeleteReportSuccess implements Action {
  readonly type = ReportActionTypes.DELETE_REPORT_SUCCESS;

  constructor(public reportId: string) {}
}

export class DeleteReportFailure implements Action {
  readonly type = ReportActionTypes.DELETE_REPORT_FAILURE;

  constructor(public error: any) {}
}

export class CreateReport implements Action {
  readonly type = ReportActionTypes.CREATE_REPORT;

  constructor(public reportName: string, public reportDescription: string) {}
}

export class CreateReportSuccess implements Action {
  readonly type = ReportActionTypes.CREATE_REPORT_SUCCESS;

  constructor(public report: any) {}
}

export class CompleteReport implements Action {
  readonly type = ReportActionTypes.COMPLETE_REPORT;

  constructor(public report: Report) {}
}

export class CompleteReportSuccess implements Action {
  readonly type = ReportActionTypes.COMPLETE_REPORT_SUCCESS;

  constructor(public report: any) {}
}

export class CompleteReportFailure implements Action {
  readonly type = ReportActionTypes.COMPLETE_REPORT_FAILURE;

  constructor(public error: any) {}
}

export class SetReportQuestions implements Action {
  readonly type = ReportActionTypes.SET_REPORT_QUESTIONS;

  constructor(public reportId: string, public questions: QuestionType[]) {}
}

export class GetReportQuestions implements Action {
  readonly type = ReportActionTypes.GET_REPORT_QUESTIONS;

  constructor() {}
}

export type ReportActions =
  | LoadReports
  | LoadReportsSuccess
  | LoadReportsFailure
  | SetReportIdBeingEdited
  | SaveReport
  | SaveReportSuccess
  | SaveReportFailure
  | DeleteReport
  | DeleteReportSuccess
  | DeleteReportFailure
  | CreateReport
  | CreateReportSuccess
  | CompleteReport
  | CompleteReportSuccess
  | CompleteReportFailure
  | SetReportQuestions
  | GetReportQuestions;
