import { CustomerActions, CustomerActionTypes } from './customer.actions';
import { CallingState, CallState } from '../../state/app.state';
import { Customer } from '@touch-apps/customers-model';

export interface CustomerState {
  customers: Customer[];
  currentCustomerIdBeingEdited: string;
  customerIdCreated: string;
  callState: CallState;
}

const initialState: CustomerState = {
  customers: [],
  currentCustomerIdBeingEdited: '',
  customerIdCreated: '',
  callState: CallingState.INIT
};

export function customerReducer(state = initialState, action: CustomerActions): CustomerState {

  switch (action.type) {

    case CustomerActionTypes.LOAD_CUSTOMERS:
      return {
        ...state,
        callState: CallingState.CALLING
      };
    case CustomerActionTypes.LOAD_CUSTOMERS_SUCCESS:
      return {
        ...state,
        callState: CallingState.CALLED,
        customers: action.customers
      };
    case CustomerActionTypes.LOAD_CUSTOMERS_FAILURE:
      return {
        ...state,
        callState: {errorMsg: action.error}
      };

    case CustomerActionTypes.SET_CUSTOMER_ID_BEING_EDITED:
      return {
        ...state,
        currentCustomerIdBeingEdited: action.customerId
      };
    case CustomerActionTypes.CREATE_CUSTOMER:
      return {
        ...state,
        customerIdCreated: '',
        callState: CallingState.CALLING
      };
    case CustomerActionTypes.SAVE_CUSTOMER:
      return {
        ...state,
        callState: CallingState.CALLING
      };
    case CustomerActionTypes.SAVE_CUSTOMER_FAILURE:
      return {
        ...state,
        callState: {errorMsg: action.error}
      };
    case CustomerActionTypes.SAVE_CUSTOMER_SUCCESS:
      return {
        ...state,
        callState: CallingState.CALLED
      };
    case CustomerActionTypes.CREATE_CUSTOMER_SUCCESS:
      return {
        ...state,
        customerIdCreated: action.customer.customerId,
        callState: CallingState.CALLED,
        customers: AddCustomer(state.customers, action.customer)
      };
    case CustomerActionTypes.DELETE_CUSTOMER:
      return {
        ...state,
        callState: CallingState.CALLING
      };
    case CustomerActionTypes.DELETE_CUSTOMER_SUCCESS:
      return {
        ...state,
        callState: CallingState.CALLED
      };
    case CustomerActionTypes.DELETE_CUSTOMER_FAILURE:
      return {
        ...state,
        callState: {errorMsg: action.error}
      };
    case CustomerActionTypes.CUSTOMER_ERROR_ACKNOWLEDGED:
      return {
        ...state,
        callState: CallingState.INIT
      };
    default: {
      return state;
    }
  }
}

const AddCustomer = (customers: Customer[], customer: Customer): Customer [] => {
  const updatedCustomers = [...customers];
  updatedCustomers.push(customer);
  return updatedCustomers;
}
