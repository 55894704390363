import { Action } from '@ngrx/store';

export enum CustomerActionTypes {

  LOAD_CUSTOMERS = '[Customer] Load Customers',
  LOAD_CUSTOMERS_SUCCESS = '[Customer] Load Customers success',
  LOAD_CUSTOMERS_FAILURE = '[Customer] Load Customers failure',
  SET_CUSTOMER_ID_BEING_EDITED = '[Customer] Set customer id being edited',
  SAVE_CUSTOMER = '[Customer] Save customer being edited',
  SAVE_CUSTOMER_SUCCESS = '[Customer] Save customer success',
  SAVE_CUSTOMER_FAILURE = '[Customer] Save customer failed',
  DELETE_CUSTOMER = '[Customer] Delete customer',
  DELETE_CUSTOMER_SUCCESS = '[Customer] Delete customer success',
  DELETE_CUSTOMER_FAILURE = '[Customer] Delete customer failed',
  CREATE_CUSTOMER = '[Customer] Create customer',
  CREATE_CUSTOMER_SUCCESS = '[Customer] Create customer success',
  CREATE_CUSTOMER_FAILURE = '[Customer] Create customer failed',
  DISPLAY_CUSTOMER_ERROR = '[Customer] Display customer error',
  CUSTOMER_ERROR_ACKNOWLEDGED = '[Customer] Customer error acknowledged'

}

export class LoadCustomers implements Action {
  readonly type = CustomerActionTypes.LOAD_CUSTOMERS;

  constructor() {
  }
}

export class LoadCustomersSuccess implements Action {
  readonly type = CustomerActionTypes.LOAD_CUSTOMERS_SUCCESS;

  constructor(public customers: any) {
  }
}

export class LoadCustomersFailure implements Action {
  readonly type = CustomerActionTypes.LOAD_CUSTOMERS_FAILURE;

  constructor(public error: any) {
  }
}

export class SetCustomerIdBeingEdited implements Action {
  readonly type = CustomerActionTypes.SET_CUSTOMER_ID_BEING_EDITED;

  constructor(public customerId: string) {
  }
}

export class SaveCustomer implements Action {
  readonly type = CustomerActionTypes.SAVE_CUSTOMER;

  constructor() {
  }
}

export class SaveCustomerSuccess implements Action {
  readonly type = CustomerActionTypes.SAVE_CUSTOMER_SUCCESS;

  constructor(public customer: any) {
  }
}

export class SaveCustomerFailure implements Action {
  readonly type = CustomerActionTypes.SAVE_CUSTOMER_FAILURE;
  constructor(public error: any) {
  }
}

export class DeleteCustomer implements Action {
  readonly type = CustomerActionTypes.DELETE_CUSTOMER;

  constructor(public customerId: string) {
  }
}

export class DeleteCustomerSuccess implements Action {
  readonly type = CustomerActionTypes.DELETE_CUSTOMER_SUCCESS

  constructor(public customerId: string) {
  }
}

export class DeleteCustomerFailure implements Action {
  readonly type = CustomerActionTypes.DELETE_CUSTOMER_FAILURE

  constructor(public error: any) {
  }
}

export class CreateCustomer implements Action {
  readonly type = CustomerActionTypes.CREATE_CUSTOMER;

  constructor(public customerName: string, public customerDescription: string) {
  }
}

export class CreateCustomerSuccess implements Action {
  readonly type = CustomerActionTypes.CREATE_CUSTOMER_SUCCESS;

  constructor(public customer: any) {
  }
}

export class CreateCustomerFailure implements Action {
  readonly type = CustomerActionTypes.CREATE_CUSTOMER_FAILURE;

  constructor(public error: any) {
  }
}

export class DisplayCustomerError implements Action {
  readonly type = CustomerActionTypes.DISPLAY_CUSTOMER_ERROR;

  constructor(public error: any) {
  }
}

export class CustomerErrorAcknowledged implements Action {
  readonly type = CustomerActionTypes.CUSTOMER_ERROR_ACKNOWLEDGED;

  constructor() {
  }
}

export type CustomerActions =

  | LoadCustomers
  | LoadCustomersSuccess
  | LoadCustomersFailure
  | SetCustomerIdBeingEdited
  | SaveCustomer
  | SaveCustomerSuccess
  | SaveCustomerFailure
  | DeleteCustomer
  | DeleteCustomerSuccess
  | DeleteCustomerFailure
  | CreateCustomer
  | CreateCustomerSuccess
  | CreateCustomerFailure
  | DisplayCustomerError
  | CustomerErrorAcknowledged;


